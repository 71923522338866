import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"

const LogoValidation = () => {
  const VALID_EXTENSIONS = /(\.jpg|\.jpeg|\.png)$/i;
  const [message, setMessage] = useState("")

  const handleFileAttachment = (e) => {
    const fileInput = document.querySelector("#organization_thumbnail_logo")
    const filePath = fileInput.value

    if (!VALID_EXTENSIONS.exec(filePath)) {
      fileInput.value = null;
      setMessage("Invalid file extension")
      return false;
    }

    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])

    img.onload = () => {
      if (img.height < 150 || img.width < 150) {
        fileInput.value = null;
        setMessage("Image is below 150px x 150px requirement.")
        return false;
      }

      document.querySelector("#logo-preview").innerHTML ="<img src=\"" + img.src + "\"/>";
      setMessage("")
    }
  }

  useEffect(() => {
    const node = document.querySelector("#organization_thumbnail_logo");
    node.addEventListener("change", handleFileAttachment);
    return () => node.removeEventListener("change", handleFileAttachment);
  });

  return (<div className="alert__message">{message}</div>)
}

export default LogoValidation;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"thumbnail-logo-validation\"]"))
    .forEach((node) => {
        ReactDOM.render(<LogoValidation />, node)
    })
});
