import React, { useState } from "react"
import ReactDOM from "react-dom"
import InvoiceDetail from "./invoice-detail"
import chevron from "../../images/chevron-down.svg"
import { formatCurrency, formatDate } from "../../utilities"

const InvoiceSummary = (props) => {
  const [displayInvoiceItems, setDisplayInvoiceItems] = useState(false);

  return (
    <>
      <li className={`expander ${props.pastDue ? "highlight" : ""}`} data-test-id={`order-po-number-${props.poNumber}`}>
        <div className="list__table">
          <div className="list__cell">
            <p>{props.accuraInvoiceId}</p>
          </div>
          <div className="list__cell">
            <p data-test-id={`accura-invoice-id-${props.accuraInvoiceId}-invoice-date`}>{props.invoiceDate ? formatDate(props.invoiceDate, { format: "M/D/YYYY" }) : "--"}</p>
          </div>
          <div className="list__cell">
            <p>{props.dueDate ? formatDate(props.dueDate, { format: "M/DD/YYYY" }) : "--"}</p>
          </div>
          <div className="list__cell list__status">
            <p>{props.displayStatus}</p>
          </div>
          <div className="list__cell">
            <p>{props.paymentTerms}</p>
          </div>
          <div className="list__cell">
            <p>
            {formatCurrency(props.invoiceAmount)}
            </p>
          </div>
          <div className="list__cell">
            <a href={`/invoices/${props.id}.pdf`} target="_blank"><span className="material-icons">description</span> download pdf</a>
          </div>
          <div
            className={`list__cell expander__btn ${displayInvoiceItems ? "btn--open" : ""}`}
            onClick={() => setDisplayInvoiceItems(!displayInvoiceItems)}
          >
            <img src={chevron} alt="reporting" className="icon--chevron"/>
          </div>
        </div>
        <div className="expander__content">
          {displayInvoiceItems && (<InvoiceDetail {...props} />)}
        </div>
      </li>
    </>
  )

}

export default InvoiceSummary;
