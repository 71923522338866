import React from "react"
import { formatCurrency } from "../../utilities";

const SpaDetail = ({ specialPricingAgreementParts }) => {
  return (
    <div className="list__details list__details--top">
      {specialPricingAgreementParts.map(({ description, apPartId, customerPartId, unitPrice, minimumOrderQuantity, eau, unitMeasure }, index) => (
         <div key={apPartId} className="line-item">
          <div className="list__cell list__cell--sm">
            <div className="list__count">
              <p><b>{index + 1}</b></p>
            </div>
          </div>
          <div className="list__cell">
            <p className="label">Part Id</p>
            <p><b>{customerPartId || "--"}</b></p>
          </div>
          <div className="list__cell">
            <p className="label">Item ID</p>
            <p><b>{apPartId}</b></p>
          </div>
          <div className="list__cell">
            <p className="label">Description</p>
            <p className="u-push-right--sm"><b>{description}</b></p>
          </div>
          <div className="list__cell">
            <p className="label">MOQ</p>
            <p className="u-push-right--sm"><b>{minimumOrderQuantity}</b></p>
          </div>
          <div className="list__cell">
            <p className="label">EAU</p>
            <p className="u-push-right--sm"><b>{eau}</b></p>
          </div>
          <div className="list__cell">
            <p className="label">Unit of Measure</p><p className="u-push-right--sm"><b>{unitMeasure}</b></p>
          </div>
          <div className="list__cell">
            <p className="label">Unit Price</p>
            <p className="u-push-right--sm">
              <b>
                {formatCurrency(unitPrice, {digits: 4})}
              </b>
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SpaDetail;
