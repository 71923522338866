import React from "react"
import { formatCurrency, formatDate, formatQuantity } from "../../utilities"

const InvoiceDetail = (props) => {
  return (
    <>
      <div className="list__details list__details--top">
        <div className="list__cell">
          <p className="caption">Customer ID</p>
          <p><b>{props.customerId}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">PO#</p>
          <p><b>{props.customerPoNumber}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">order #</p>
          <p><b>{props.accuraOrderId}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">currency</p>
          <p><b>{props.currency}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">ship date</p>
          <p><b>{props.shipDate ? formatDate(props.shipDate, { format: "M/DD/YYYY" }) : "--"}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">FOB</p>
          <p><b>{props.fob ? props.fob : "--"}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Bill To</p>
          <p><b>{props.billToName}</b></p>
          <p><b>{props.billToAddress1}</b></p>
          <p><b>{props.billToAddress2}</b></p>
          <p><b>{props.billToAddress3}</b></p>
          <p><b>{props.billToCity}, {props.billToState} {props.billToPostalCode}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Ship To</p>
          <p><b>{props.shipToName}</b></p>
          <p><b>{props.shipToAddress1}</b></p>
          <p><b>{props.shipToAddress2}</b></p>
          <p><b>{props.shipToAddress3}</b></p>
          <p><b>{props.shipToCity}, {props.shipToState} {props.shipToPostalCode}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">shipping method</p>
          <p><b>{props.shippingMethod}</b></p>
        </div>

        <div className="list__cell u-left">
          <p className="caption">Invoice PDF</p>
          <a href={`/invoices/${props.id}.pdf`} target="_blank"><span className="material-icons">description</span> download pdf</a>
        </div>

        {props.shipmentPackingListId && (
          <div className="list__cell u-left">
            <p className="caption">Packing Slip PDF</p>
            <p className="u-push-bottom--xs">
              <a href={`/packing_lists/${props.shipmentPackingListId}.pdf`} target="_blank"><span className="material-icons">description</span> download</a>
            </p>
        </div>
        )}
        {props.orderId && (
          <div className="list__cell u-left">
            <p className="caption">Order Confirmation PDF</p>
            <a href={`/orders/${props.orderId}.pdf`} target="_blank"><span className="material-icons">description</span> download pdf</a>
          </div>
        )}

        {props.invoiceItems.sort((a, b) => (a.lineNumber - b.lineNumber)).map(item => (
          <div className="line-item" key={`props-item-${item.id}`}>
            <div className="list__cell list__cell--sm">
              <div className="list__count">
                <p><b>{item.lineNumber}</b></p>
              </div>
            </div>

            <div className="list__cell">
              <p className="label">Part Id</p>
              <p className="u-push-right--sm"><b>{item.customerPartId}</b></p>
              <p className="u-push-bottom--none"><b>{item.partId}</b></p>
            </div>

            <div className="list__cell">
              <p className="label">Ordered</p>
              <p className="u-push-right--sm"><b>
                {formatQuantity(item.orderedQuantity)}
              </b> <span className="label">{item.unitOfMeasure}</span></p>
            </div>

            <div className="list__cell">
              <p className="label">Shipped</p>
              <p className="u-push-right--sm"><b>
                {formatQuantity(item.shippedQuantity)}
              </b> <span className="label">{item.unitOfMeasure}</span></p>
            </div>

            <div className="list__cell">
              <p className="label">Remaining</p>
              <p className="u-push-right--sm"><b>
                {formatQuantity(item.remainingQuantity)}
              </b> <span className="label">{item.unitOfMeasure}</span></p>
            </div>

            <div className="list__cell">
              <p className="label">Unit Price</p>
              <p className="u-push-right--sm"><b>
                {formatCurrency(item.unitPrice)}
              </b> <span className="label">per {item.unitOfMeasure}</span></p>
            </div>

            <div className="list__cell">
              <p className="label">Extended</p>
              <p className="u-push-right--sm"><b>
                {formatCurrency(item.extendedAmount)}
              </b></p>
            </div>

            <div className="list__cell">
              <p className="label">Tax</p>
              <p className="u-push-right--sm"><b>
                {formatCurrency(item.taxAmount)}
              </b></p>
            </div>
          </div>
        ))}

      </div>
    </>
  )
}

export default InvoiceDetail;
