import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import _ from "lodash"
import PartRow from "./part-row"
import LoadingBraid from "../utilities/loading-braid"
import { getData } from "../../utilities/async"
import { formatDate, stripAndDowncase } from "../../utilities"

const Parts = (props) => {
  const displayInventory = props.ordersByInventory == "true";

  const [parts, setParts] = useState([])
  const [spaLink, setSpaLink] = useState(null)
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")

  const [sortColumn, setSortColumn] = useState(displayInventory ? "ap1_inventory" : "customer_part_id")
  const [sortDirection, setSortDirection] = useState(displayInventory ? "desc" : "asc")

  useEffect(() => {
    setLoading(true)

    getData("/api/parts/", { sort_column: sortColumn, sort_direction: sortDirection })
      .then(res => {
        setParts(res.data.parts)
        setSpaLink(res.data.spaLink)
        setLoading(false)
      })
  }, [sortColumn, sortDirection])

  const filteredParts = parts.filter((part) => {
    const target = stripAndDowncase(searchTerm);

    const matchesApPartId = _.startsWith(stripAndDowncase(part.apPartId), target)
    const matchesCustomerPartId = _.startsWith(stripAndDowncase(part.customerPartId), target)

    return matchesApPartId || matchesCustomerPartId;
  })

  const handleSortToggle = (targetColumn) => {
    if (targetColumn == sortColumn) {
      setSortDirection((sortDirection == "asc" ? "desc" : "asc"))
    } else {
      setSortColumn(targetColumn)
    }
  }

  const sortArrow = (column) => {
    if (sortColumn === column && sortDirection === "asc") {
      return <span className="material-icons">keyboard_arrow_up</span>;
    }
    if (sortColumn === column && sortDirection === "desc") {
      return <span className="material-icons">keyboard_arrow_down</span>
    } else {
      return <span className="material-icons">unfold_less</span>;
    }
  };

  return (
    <>
      {loading && ( <LoadingBraid /> )}

      <div className="u-push-bottom">
        <div className="list__header">
          <div className="list__cell u-left">
            <h2 className="u-push-right--sm">Parts</h2>
          </div>
          <div className="list__cell--xlg">
            <p>
              {spaLink && <a href={spaLink}>SPA list</a>}
              {props.partsRepEmail && (
                <span>parts rep: <a href={`mailto:${props.partsRepEmail}`}>{props.partsRepEmail}</a></span>
              )}
            </p>
          </div>
          <div className="list__cell--lg u-right">
            <div className="input--search">
              <input
                placeholder="Search Part ID"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
          </div>
        </div>
        <p className="caption">Part information last updated on <b className="blue">{formatDate(props.partsUpdatedAt, { format: "M/D/YYYY, h:mm A" })}</b></p>
      </div>
      <div>
        <ul className="list list--orders">
          <li className="table__header">
            <div className="list__table">
              <div className="list__cell" onClick={(e) => handleSortToggle("customer_part_id")}>
                <p className={`list__sort ${sortColumn == "customer_part_id" ? "active" : ""}`}>
                  Customer Part ID {sortArrow("customer_part_id")}
                </p>
              </div>
              <div className="list__cell"><p>A&P Part ID</p></div>
              <div className="list__cell list__cell--lg"><p>Part Description</p></div>
              <div className="list__cell"><p>Unit of Measure</p></div>
              <div className="list__cell"><p>Price</p></div>
              {displayInventory && (
                <div className="list__cell" onClick={() => handleSortToggle("ap1_inventory")}>
                  <p className={`list__sort ${sortColumn == "ap1_inventory" ? "active" : ""}`}>
                    Inventory {sortArrow("ap1_inventory")}
                  </p>
                </div>
              )}
              <div className="list__cell">
                <a href={props.partsCsvPath}><span className="material-icons">description</span> export csv</a>
              </div>
            </div>
          </li>
          {filteredParts.map(part => <PartRow key={part.id} displayInventory={displayInventory} {...part} />)}
          </ul>
        <div className="u-push-bottom--xlg"></div>
      </div>
    </>
  )
}

export default Parts;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"parts\"]"))
    .forEach((node) => {
        const props = node.dataset;

        ReactDOM.render(<Parts {...props} />, node)
    })
});
