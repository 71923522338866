// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "images"
import "fonts"
import "sass/application.scss"

import "components/invoices"
import "components/shipments"
import "components/orders"
import "components/parts"
import "components/parts/part-history"
import "components/tickets"
import "components/passwords/password-validation"
import "components/organizations/logo-validation"
import "components/spas"