import React, { useState } from "react"
import TicketDetail from "./ticket-detail"
import chevron from "../../images/chevron-down.svg"
import { formatDate } from "../../utilities";

const TicketRow = (props) => {

  const { apPartId, openedOn, issue, ticketNumber, ticketIssueNumber, status, issueType, description } = props;
  const [displayTicketDescription, setDisplayTicketDescription] = useState(false);

  return (
    <li>
      <div className="list__table">
        <div className="list__cell"><p>{ticketNumber}</p></div>
        <div className="list__cell list__cell--lg"><p>{issueType}</p></div>
        <div className="list__cell list__cell--lg"><p>{issue}</p></div>
        <div className="list__cell"><p>{apPartId}</p></div>
        <div className="list__cell"><p>{formatDate(openedOn, { format: "M/DD/YYYY" })}</p></div>
        <div className="list__cell"><p>{status}</p></div>
        <div
          className={`list__cell expander__btn ${displayTicketDescription ? "btn--open" : ""}`}
          onClick={() => setDisplayTicketDescription(!displayTicketDescription)}
        >
          <img src={chevron} alt="reporting" className="icon--chevron"/>
        </div>
      </div>
      <div className="expander__content">
        {displayTicketDescription && (<TicketDetail {...props} />)}
      </div>
    </li>
  )
}

export default TicketRow;
