import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import _, { debounce } from "lodash"
import ShipmentSummary from "./shipment-summary"
import LoadingBraid from "../utilities/loading-braid"
import { getData } from "../../utilities/async"
import { formatDate } from "../../utilities/index"

const Shipments = (props) => {
  const [shipments, setShipments] = useState(null)
  const [pages, setPages] = useState([])
  const [selectedPage, setSelectedPage] = useState({})
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    getData("/api/shipments/", {}).then(res => {
      setPages(res.data.pages)
      setSelectedPage(res.data.currentPage)
    })
  }, [])

  useEffect(() => {
    setLoading(true)

    const { query, startDate, endDate } = selectedPage;

    getData("/api/shipments/", { query, start_date: startDate, end_date: endDate }).then(res => {
      setShipments(res.data.shipments)
      setLoading(false)
    })
  }, [selectedPage])

  const debounceSearchTerm = _.debounce((text) => {
    setSearchTerm(text)

    if (text.length >= 3) {
      setSelectedPage({ query: text })
    }

    if (text.length == 0) {
      setSelectedPage(pages[0]) // todo make this beginning of quarter
    }
  }, 300);

  const displayCoc = () => !_.isNil(_.find(shipments, (shipment) => shipment.showCertificateOfConformance));
  const displayCoa = () => !_.isNil(_.find(shipments, (shipment) => shipment.showCertificateOfAnalysis));

  return (
    <>
      <div className="list__header">
        <div className="list__title u-left">
          <h2 className="u-push-right--sm">Shipments</h2>
        </div>
        <div className="list__search u-right">
          <div className="input--search">
            <input
              id="shipments_search"
              placeholder="Search Batch # or Invoice #"
              data-test="shipment-search"
              onChange={(e) => debounceSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="u-push-top--sm u-push-bottom--sm">
        <p className="caption u-center">
          {pages.filter(page => page.startDate).map(page => {
            return (
              <a
                key={formatDate(page.startDate, { format: "MMM-DD-YYYY" })}
                href="#"
                className={`u-push-right--sm ${selectedPage.startDate == page.startDate ? "active" : ""}`}
                onClick={() => setSelectedPage(page)}
              >
                {!page.endDate ? `today - ${formatDate(page.startDate, { format: "MMM 'YY" })}` : `${formatDate(page.endDate, { format: "MMM", prevMonth: true })} - ${formatDate(page.startDate, { format: "MMM 'YY" })}`}
              </a>
            )
          })}
        </p>
      </div>

      <ul className="list list--shipments">
        <li className="table__header">
          <div className="list__table">
            <div className="list__cell"><p>Ship Date</p></div>
            <div className="list__cell"><p>Batch #</p></div>
            <div className="list__cell"><p>Packing List</p></div>
            <div className="list__cell"><p>Tracking</p></div>
            {displayCoc() && <div className="list__cell"><p>COC</p></div>}
            {displayCoa() && <div className="list__cell"><p>COA</p></div>}
            <div className="list__cell">
              <a href={props.shipmentsCsvPath}><span className="material-icons">description</span> export csv</a>
            </div>
            <div className="list__cell--xlg"><p>Invoice #(s)</p></div>
          </div>
          </li>
        {shipments && !loading && shipments.length == 0 && <div className="u-padded u-center">No results found.</div>}
        {shipments && shipments.map(shipment => <ShipmentSummary key={shipment.id} displayCoc={displayCoc()} displayCoa={displayCoa()} {...shipment} />)}
      </ul>
    </>
  )
}

export default Shipments;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"shipments\"]"))
    .forEach((node) => {
        const props = node.dataset;

        ReactDOM.render(<Shipments {...props} />, node)
    })
});
