import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import _, { debounce } from "lodash"
import InvoiceSummary from "./invoice-summary"
import LoadingBraid from "../utilities/loading-braid"
import { getData } from "../../utilities/async"
import { formatDate } from "../../utilities"

const Invoices = (props) => {
  const [invoices, setInvoices] = useState(null);
  const [pages, setPages] = useState([])
  const [selectedPage, setSelectedPage] = useState({})
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getData("/api/invoices/", {}).then(res => {
      setPages(res.data.pages)
      setSelectedPage(res.data.currentPage)
    })
  }, [])

  useEffect(() => {
    setLoading(true)

    const { query, startDate, endDate } = selectedPage;

    getData("/api/invoices/", { query, start_date: startDate, end_date: endDate }).then(res => {
      setInvoices(res.data.invoices)
      setLoading(false)
    })
  }, [selectedPage])

  const debounceSearchTerm = _.debounce((text) => {
    setSearchTerm(text)

    if (text.length >= 3) {
      setSelectedPage({ query: text })
    }

    if (text.length == 0) {
      setSelectedPage({ status: "unpaid" })
    }
  }, 300);

  return (
    <>
      {loading && ( <LoadingBraid /> )}
      <div className="list__header">
        <div className="list__title u-left">
          <h2 className="u-push-right--sm">Invoices</h2>
        </div>
        <div className="list__email">
          {props.invoiceRepEmail && (
            <p>invoice rep: <a href="mailto:{tbd}">{props.invoiceRepEmail}</a></p>
          )}
        </div>
        <div className="list__search">
          <div className="input--search">
            <input
              placeholder="Search by part id or invoice #"
              data-test="invoice-search"
              onChange={(e) => debounceSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="u-push-top--sm u-push-bottom--sm">
        <p className="caption u-center">
          <a
            href="#"
            className={`u-push-right--sm ${selectedPage.status == "unpaid" ? "active" : ""}`}
            onClick={() => setSelectedPage({ status: "unpaid" })}
          >
            unpaid
          </a>

          {pages.filter(page => page.startDate).map(page => {
            return (
              <a
                key={formatDate(page.startDate, { format: "MMM-DD-YYYY" })}
                href="#"
                className={`u-push-right--sm ${selectedPage.startDate == page.startDate ? "active" : ""}`}
                onClick={() => setSelectedPage(page)}
              >
                {!page.endDate ? `today - ${formatDate(page.startDate, { format: "MMM 'YY" })}` : `${formatDate(page.endDate, { format: "MMM", prevMonth: true })} - ${formatDate(page.startDate, { format: "MMM 'YY" })}`}
              </a>
            )
          })}
        </p>
      </div>
      <ul className="list list--orders">
        <li className="table__header">
          <div className="list__table">
            <div className="list__cell"><p>Invoice Number</p></div>
            <div className="list__cell"><p>Invoiced Date</p></div>
            <div className="list__cell"><p>Due Date</p></div>
            <div className="list__cell"><p>Status</p></div>
            <div className="list__cell"><p>Payment Terms</p></div>
            <div className="list__cell"><p>Invoice Total</p></div>
            <div className="list__cell">
              <a href={props.invoicesCsvPath}><span className="material-icons">description</span> export csv</a>
            </div>
            <div className="list__cell"></div>
          </div>
        </li>
        {invoices && !loading && invoices.length == 0 && <div className="u-padded u-center">No results found.</div>}
        {invoices && invoices.map(invoice => <InvoiceSummary key={invoice.id} {...invoice} />)}
      </ul>
    </>
  )
}

export default Invoices;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"invoices\"]"))
    .forEach((node) => {
        const props = node.dataset;

        ReactDOM.render(<Invoices {...props} />, node)
    })
});
