import React, { useState } from "react"
import ReactDOM from "react-dom"
import OrderDetail from "./order-detail"
import chevron from "../../images/chevron-down.svg"
import { formatCurrency, formatDate } from "../../utilities"
import { deleteData } from "../../utilities/async"


const OrderSummary = (props) => {
  const [displayOrderItems, setDisplayOrderItems] = useState(false);

  const displayedShipDate = props.shipDate || props.requiredDate;

  const shipDate = () => {
    if (props.pending == true) {
      return "pending";
    } else {
      return formatDate(displayedShipDate, { format: "MM/DD/YYYY" });
    }
  }

  const deletePurchaseOrder = () => {
    deleteData(`/api/orders/${props.id}/purchase_order`).then(res => {
      props.refreshOrder(res.data.order)
    })


  }

  return (
    <>
      <li className={`expander ${props.pending == true ? "highlight" : ""}`} data-test-order-po-number={props.poNumber}>
        <div className="list__table">
          <div className="list__cell">
            <div className="list__count">
              <p>{props.orderItems.length}</p>
            </div>
          </div>
          <div className="list__cell list__cell--lg"><p>{props.poNumber} / {props.accuraOrderId}</p></div>
          <div className={`list__cell ${props.pending == true ? "list__status" : ""}`}>
            <p data-test-id={`order-po-number-${props.poNumber}-ship-date`}>{shipDate()}</p>
          </div>
          <div className="list__cell"><p data-test-id={`order-po-number-${props.poNumber}-status`}>{props.status}</p></div>
          <div className="list__cell"><p>
            {formatCurrency(props.orderAmount)}
          </p></div>
          <div className="list__cell">
            <a href={`/orders/${props.id}.pdf`} target="_blank"><span className="material-icons">description</span>confirmation pdf</a>
          </div>
          <div className="list__cell list__btns">
            {props.purchaseOrderAttached ? (
              <a data-test-id={`download-link-${props.id}`} href={`${props.purchaseOrderPath}`} download><span className="material-icons">description</span> po pdf</a>
            ) : <a href={`/orders/${props.id}/purchase_order_form`}><span className="material-icons">upload_file</span> add po</a> }

            {props.purchaseOrderAttached ? (
              <button data-test-id={`order-${props.id}-delete-purchase-order-button`} onClick={deletePurchaseOrder} className="u-push-left--sm"><span className="material-icons">delete</span></button> ) : ""
            }</div>

          <div
            className={`list__cell expander__btn ${displayOrderItems ? "btn--open" : ""}`}
            onClick={() => setDisplayOrderItems(!displayOrderItems)}
          >
            <img src={chevron} alt="reporting" className="icon--chevron"/>
          </div>
        </div>
        <div className="expander__content">
          {displayOrderItems && (<OrderDetail {...props} />)}
        </div>
      </li>
    </>
  )
}

export default OrderSummary;
