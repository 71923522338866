import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import _ from "lodash"
import TicketRow from "./ticket-row"
import { getData } from "../../utilities/async"
import { stripAndDowncase } from "../../utilities"

const Tickets = (props) => {
  const [ticketIssues, setTicketIssues] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    getData("/api/ticket_issues/", {})
      .then(res => {
        setTicketIssues(res.data.ticketIssues)
      })
  }, [])

  const filteredTicketIssues = ticketIssues.filter((ticketIssue) => {
    const target = stripAndDowncase(searchTerm);

    const matchesApPartId = _.startsWith(stripAndDowncase(ticketIssue.apPartId), target)
    const matchesTicketNumber = _.startsWith(stripAndDowncase(String(ticketIssue.ticketNumber)), target)

    return matchesApPartId || matchesTicketNumber;
  })

  return (
    <div>
      <div className="list__header u-push-bottom">
        <div className="list__cell u-left">
          <h2 className="u-push-right--sm">Quality Tickets</h2>
        </div>
        <div className="list__cell--xlg">

        </div>
        <div className="list__cell--lg u-right">
          <div className="input--search">
            <input
              id="tickets_search"
              placeholder="Search Ticket # or Part ID"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </div>
        </div>
      </div>
      <div>
        <ul className="list list--orders">
          <li className="table__header">
            <div className="list__table">
              <div className="list__cell"><p>Ticket #</p></div>
              <div className="list__cell list__cell--lg"><p>Type</p></div>
              <div className="list__cell list__cell--lg"><p>Issue</p></div>
              <div className="list__cell"><p>Part #</p></div>
              <div className="list__cell"><p>Opened On</p></div>
              <div className="list__cell"><p>Status</p></div>
              <div className="list__cell">
                <a href={props.ticketIssuesCsvPath}><span className="material-icons">description</span> export csv</a>
              </div>
            </div>
          </li>

          {filteredTicketIssues.map(ticketIssue => {
            return (<TicketRow key={ticketIssue.ticketIssueNumber} {...ticketIssue} />)
          })}
        </ul>
        <div className="u-push-bottom--xlg"></div>
      </div>
    </div>
  )
}

export default Tickets;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"tickets\"]"))
    .forEach((node) => {
        const props = node.dataset;

        ReactDOM.render(<Tickets {...props} />, node)
    })
});
