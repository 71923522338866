import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import _, { debounce } from "lodash"
import SpaSummary from "./spa-summary"
import LoadingBraid from "../utilities/loading-braid"
import { getData } from "../../utilities/async"
import { stripAndDowncase } from "../../utilities"

const Spas = (props) => {
  const [spas, setSpas] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    getData("/api/special_pricing_agreements/", {})
      .then(res => {
        setSpas(res.data.specialPricingAgreements)
      })
  }, [])

  const filteredSpas = spas.filter((spa) => {
    const target = stripAndDowncase(searchTerm);

    const matchesSpaId = _.startsWith(stripAndDowncase(spa.spaId), target)

    const matchesPartId = spa.specialPricingAgreementParts.some(part => {
      return _.startsWith(stripAndDowncase(part.apPartId), target)
    })

    return matchesSpaId || matchesPartId;
  })

  return (
    <div>
      <div className="list__header u-push-bottom">
        <div className="list__cell u-left">
          <h2 className="u-push-bottom--sm">SPA </h2>
        </div>
        <div className="list__cell--xlg">

        </div>
        <div className="list__cell--lg u-right">
          <div className="input--search">
            <input
              id="tickets_search"
              placeholder="Search Spa # or Part ID"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </div>
        </div>
      </div>
      <div>
        <ul className="list list--orders">
          <li className="table__header">
            <div className="list__table">
              <div className="list__table">
                <div className="list__cell"><p>SPA #</p></div>
                <div className="list__cell"><p>End User</p></div>
                <div className="list__cell"><p># Of Parts</p></div>
                <div className="list__cell"><p>SPA Value</p></div>
                <div className="list__cell"><p>Expiration Date</p></div>
                <div className="list__cell"></div>
              </div>
            </div>
          </li>

          {filteredSpas.map(spa => {
            return (<SpaSummary key={spa.spaId} {...spa} />)
          })}
        </ul>
        <div className="u-push-bottom--xlg"></div>
      </div>
    </div>
  )
}

export default Spas;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"spas\"]"))
    .forEach((node) => {
      const props = node.dataset;

      ReactDOM.render(<Spas {...props} />, node)
    })
});
