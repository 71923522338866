import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import _, { debounce } from "lodash"
import OrderSummary from "./order-summary"
import LoadingBraid from "../utilities/loading-braid"
import { getData } from "../../utilities/async"
import { formatDate } from "../../utilities"

const Orders = (props) => {
  const [orders, setOrders] = useState(null)
  const [pages, setPages] = useState([])
  const [selectedPage, setSelectedPage] = useState({})
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    getData("/api/orders/", {}).then(res => {
      setPages(res.data.pages)
      setSelectedPage(res.data.currentPage)
    })
  }, [])

  useEffect(() => {
    setLoading(true)

    const { query, startDate, endDate } = selectedPage;

    getData("/api/orders/", { query, start_date: startDate, end_date: endDate }).then(res => {
      setOrders(res.data.orders)
      setLoading(false)
    })
  }, [selectedPage])

  const debounceSearchTerm = _.debounce((text) => {
    setSearchTerm(text)

    if (text.length >= 3) {
      setSelectedPage({ query: text })
    }

    if (text.length == 0) {
      setSelectedPage({ status: "open" })
    }
  }, 300);

  const refreshOrder = (refreshedOrder) => {
    let clonedOrders = Array.from(orders)
    let indexPosition = clonedOrders.findIndex((item) => item.id === refreshedOrder.id)
    clonedOrders[indexPosition] = refreshedOrder
    setOrders(clonedOrders)
  }

  return (
    <>
      <div className="list__header">
        <div className="list__title u-left">
          <h2 className="u-push-right--sm">Orders</h2>
        </div>
        <div className="list__email">
          {props.ordersRepEmail && (
            <p>orders rep: <a href="mailto:{props.ordersRepEmail}">{props.ordersRepEmail}</a></p>
          )}
        </div>
        <div className="list__search u-right">
          <div className="input--search">
            <input
              id="orders_search"
              placeholder="Search PO# or Part ID"
              data-test="order-search"
              onChange={(e) => debounceSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="u-push-top--sm u-push-bottom--sm">
        <p className="caption u-center">
          <a
            href="#"
            className={`u-push-right--sm ${selectedPage.status == "open" ? "active" : ""}`}
            onClick={() => setSelectedPage({ status: "open" })}
          >
            open
          </a>

          {pages.filter(page => page.startDate).map(page => {
            return (
              <a
                key={formatDate(page.startDate, { format: "MMM-DD-YYYY" })}
                href="#"
                className={`u-push-right--sm ${selectedPage.startDate == page.startDate ? "active" : ""}`}
                onClick={() => setSelectedPage(page)}
              >
                {!page.endDate ? `today - ${formatDate(page.startDate, { format: "MMM 'YY" })}` : `${formatDate(page.endDate, { format: "MMM", prevMonth: true })} - ${formatDate(page.startDate, { format: "MMM 'YY" })}`}
              </a>
            )
          })}
        </p>
      </div>

      <ul className="list list--orders">
        <li className="table__header">
          <div className="list__table">
            <div className="list__cell"><p>Item Count</p></div>
            <div className="list__cell list__cell--lg"><p>PO# / Order</p></div>
            <div className="list__cell"><p>Ship Date</p></div>
            <div className="list__cell"><p>Status</p></div>
            <div className="list__cell"><p>Order Amount</p></div>
            <div className="list__cell"><p>Confirmation</p></div>
            <div className="list__cell"><p>Purchase Order</p></div>
            <div className="list__cell"> <a href={props.ordersCsvPath}><span className="material-icons">description</span> export csv</a></div>
          </div>
        </li>
        {orders && !loading && orders.length == 0 && <div className="u-padded u-center">No results found.</div>}
        {orders && orders.map(order => <OrderSummary refreshOrder={refreshOrder} key={order.id} {...order} />)}
      </ul>
    </>
  )
}

export default Orders;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"orders\"]"))
    .forEach((node) => {
        const props = node.dataset;

        ReactDOM.render(<Orders {...props} />, node)
    })
});
