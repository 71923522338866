import React from "react"

const TicketDetail = ({ description }) => {
  return (
    <div className="list__details list__details--top">
      <div className="list__cell u-push-top"><p>{ description }</p></div>
    </div>
  )
}

export default TicketDetail;
