import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"

const PasswordValidation = () => {
  const MINIMUM_PASSWORD_LENGTH = 8;
  const VALID_SPECIAL_CHARACTERS = "|.,!@#$%^&*".split("");

  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePasswordConfirmationChange = (e) => setPasswordConfirmation(e.target.value);

  const hasLowerCaseChar = (pwd) => /[a-z]/.test(pwd);
  const hasUpperCaseChar = (pwd) => /[A-Z]/.test(pwd);
  const hasNumericalChar = (pwd) => /[0-9]/.test(pwd);
  const hasSpecialChar = (pwd) => pwd.split("").some(char => VALID_SPECIAL_CHARACTERS.includes(char));



  const isValidLength = (pwd) => pwd.length >= MINIMUM_PASSWORD_LENGTH;

  const hasValidPassword = (pwd) => {
    return hasLowerCaseChar(pwd) && hasUpperCaseChar(pwd) && hasNumericalChar(pwd) && hasSpecialChar(pwd) && isValidLength(pwd);
  }

  useEffect(() => {
    const node = document.querySelector("#user_password");
    node.addEventListener("keyup", handlePasswordChange);
    return () => node.removeEventListener("keyup", handlePasswordChange);
  });

  useEffect(() => {
    const node = document.querySelector("#user_password_confirmation");
    node.addEventListener("keyup", handlePasswordConfirmationChange);
    return () => node.removeEventListener("keyup", handlePasswordConfirmationChange);
  });

  const passwordValidationMessage = () => {
    if (password === "" && passwordConfirmation === "") {
      return "";
    } else if (!hasLowerCaseChar(password)) {
      return (
        <div className="password__status"><p>password must contain a lowercase character</p> <div className="password__icon--match">&#8211;</div></div>
      )
    } else if (!hasUpperCaseChar(password)) {
      return (
        <div className="password__status"><p>password must contain an uppercase character</p> <div className="password__icon--match">&#8211;</div></div>
      )
    } else if (!hasNumericalChar(password)) {
      return (
        <div className="password__status"><p>password must contain a numerical character</p> <div className="password__icon--match">&#8211;</div></div>
      )
    } else if (!hasSpecialChar(password)) {
      return (
        <div className="password__status"><p>password must contain a special character ({ VALID_SPECIAL_CHARACTERS.join(", ") })</p> <div className="password__icon--match">&#8211;</div></div>
      )
    } else if (!isValidLength(password)) {
      return (
        <div className="password__status"><p>password length too short ({ MINIMUM_PASSWORD_LENGTH } characters minimum)</p> <div className="password__icon--match">&#8211;</div></div>
      )
    }
    else if (password !== passwordConfirmation) {
      return (
        <div className="password__status"><p>enter matching passwords</p> <div className="password__icon--match">&#8211;</div></div>
      )
    } else {
      return (
        <div className="password__status"><p>password looks good!</p> <div className="password__icon--valid">&#10003;</div></div>
      )
    }
  }

  return (
    <div>
      { passwordValidationMessage() }
    </div>
  )
}

export default PasswordValidation;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"password-validation\"]"))
    .forEach((node) => {
        ReactDOM.render(<PasswordValidation />, node)
    })
});
