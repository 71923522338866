import axios from "axios"
import humps from "humps"

export const getData = (endpoint, params = {}) => {
  return axios.get(endpoint, {
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content")
    },
    transformResponse: [
      ...axios.defaults.transformResponse,
      data => humps.camelizeKeys(data)
    ],
    transformRequest: [
      data => humps.decamelizeKeys(data),
      ...axios.defaults.transformRequest
    ]
  })
}

export const deleteData = (endpoint) => {
  return axios.delete(endpoint, {
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content")
    },
    transformResponse: [
      ...axios.defaults.transformResponse,
      data => humps.camelizeKeys(data)
    ],
    transformRequest: [
      ...axios.defaults.transformRequest
    ]
  })
}