import React, { useState } from "react"
import ReactDOM from "react-dom"
import { formatCurrency, formatQuantity } from "../../utilities";

const PartRow = (props) => {
  const { apPartId, customerPartId, itemDescription, unitMeasure, price, ap1Inventory, displayInventory } = props;

  return (
    <li>
      <div className="list__table">
        <div className="list__cell"><b>{customerPartId || "--" }</b></div>
        <div className="list__cell"><b>{apPartId || "--"}</b></div>
        <div className="list__cell list__cell--lg u-lowercase"><b>{itemDescription}</b></div>
        <div className="list__cell"><b>{unitMeasure}</b></div>
        <div className="list__cell">
          <b>
            {formatCurrency(price, {digits: 4})}
          </b>
        </div>
        {displayInventory && (
          <div className="list__cell">
            <b>
              {formatQuantity(ap1Inventory, {digits: 0})}
            </b>
          </div>
        )}
        <div className="list__cell"></div>
      </div>
    </li>
  )
}

export default PartRow;
