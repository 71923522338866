import React from "react"
import { formatCurrency, formatDate, formatQuantity } from "../../utilities";

const OrderDetail = (props) => {
  return (
    <>
      <div className="list__details">
        <div className="list__cell">
          <p className="caption">Order Date</p>
          <p><b>{formatDate(props.orderDate, { format: "MM/DD/YYYY" })}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">order ID</p>
          <p><b>{props.accuraOrderId}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Payment Terms</p>
          <p><b>{props.paymentTerms}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">FOB</p>
          <p><b>{props.fob || "--"}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Shipping Method</p>
          <p><b>{props.shippingMethod}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Tracking Number</p>
          <p><b>{props.trackingNumber || "--"}</b></p>
        </div>
        <div className="list__cell">
          <p><strong>Bill To</strong></p>
          <p className="caption">Name</p>
          <p><b> {props.billToName} </b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Contact</p>
          <p><b>{props.billToContact}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Address</p>
          <p><b>{props.billToAddress1} </b></p>
          <p><b>{props.billToAddress2}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">City</p>
          <p><b>{props.billToCity}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">State</p>
          <p><b>{props.billToState}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Postal Code</p>
          <p><b>{props.billToPostalCode}</b></p>
        </div>

        <div className="list__cell">
          <p><strong>Ship To</strong></p>
          <p className="caption">Name</p>
          <p><b>{props.shipToName}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Contact</p>
          <p><b>{props.shipToContact}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Address</p>
          <p><b>{props.shipToAddress1}</b></p>
          <p><b>{props.shipToAddress2}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">City</p>
          <p><b>{props.shipToCity}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">State/Country</p>
          <p><b>{props.shipToState} {props.shipToCountry}</b></p>
        </div>
        <div className="list__cell">
          <p className="caption">Postal Code</p>
          <p><b>{props.shipToPostalCode}</b></p>
        </div>

        {props.orderItems.sort((a, b) => (a.lineNumber - b.lineNumber)).map(item => (
          <div className="line-item" key={`props-item-${item.id}`}>
            <div className="list__cell list__cell--sm">
              <div className="list__count"><p><b>{item.lineNumber}</b></p></div>
            </div>
            <div className="list__cell">
              <p className="label">Part Id</p>
              <p className="u-push-right--sm"><b>{item.customerPartId}</b></p>
              <p className="label u-push-bottom--none"><b>{item.partId}</b></p>
            </div>
            <div className="list__cell list__cell--lg">
              <p className="label">Part Description</p>
              <p className="caption u-push-right u--lowercase"><b>{item.description}</b></p>
            </div>
            <div className="list__cell">
              <p className="label">Original Qty.</p>
              <p className="u-push-right--sm"><b>
                {formatQuantity(item.originalQuantity)}
              </b> <span className="label">{item.unitOfMeasure}</span></p>
            </div>
            <div className="list__cell">
              <p className="label">Remaining Qty.</p>
              <p className="u-push-right--sm"><b>
                {formatQuantity(item.remainingQuantity)}
              </b> <span className="label">{item.unitOfMeasure}</span></p>
            </div>
            <div className="list__cell">
              <p className="label">Unit Price</p>
              <p className="u-push-right--sm"><b>
                {formatCurrency(item.unitPrice, {digits: 4})}
              </b> <span className="label">per {item.unitOfMeasure}</span></p>
            </div>
            <div className="list__cell">
              <p className="label">Amount</p>
              <p className="u-push-right--sm"><b>
                {formatCurrency(item.itemAmount)}
              </b></p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default OrderDetail;
