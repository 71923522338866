import React, { useState } from "react"
import SpaDetail from "./spa-detail"
import chevron from "../../images/chevron-down.svg"
import moment from "moment"
import { formatCurrency, formatDate } from "../../utilities"

const SpaSummary = (props) => {

  const { spaId, specialPricingAgreementParts, endUserName, expiresOn, spaValue } = props;
  const [displaySpaDescription, setDisplaySpaDescription] = useState(false);

  return (
    <li className={`expander ${moment().isAfter(expiresOn) ? "highlight" : ""}`} data-test-id={`spa-id-${spaId}`}>
      <div className="list__table">
        <div className="list__cell">
          <p>{spaId}</p>
        </div>
        <div className="list__cell">
          <p>{endUserName || "--"}</p>
        </div>
        <div className="list__cell">
          <p className="caption">{specialPricingAgreementParts.length}</p>
        </div>
        <div className="list__cell">
          <p className="caption" data-test-id={`spa-id-${spaId}-spa-value`}>{formatCurrency(spaValue)}</p>
        </div>
        <div className="list__cell">
          <p className="caption" data-test-id={`spa-id-${spaId}-expires-on`}>{formatDate(expiresOn)}</p>
        </div>
        <div
          className={`list__cell expander__btn ${displaySpaDescription ? "btn--open" : ""}`}
          onClick={() => setDisplaySpaDescription(!displaySpaDescription)}
        >
          <img src={chevron} alt="reporting" className="icon--chevron" />
        </div>
      </div>
      <div className="expander__content">
        {displaySpaDescription && (<SpaDetail {...props} />)}
      </div>
    </li>
  )
}

export default SpaSummary;
