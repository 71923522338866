import React, { useEffect, useState, PureComponent } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import _ from "lodash";
import { getData } from "../../utilities/async";

const PartHistoryChart = (props) => {
  const customerPartId = props.customerPartId;
  const [data, setData] = useState([])
  const [displayValue, setDisplayValue] = useState("cost")
  const [consistentUM, setConsistentUM] = useState(true)
  const [unitMeasure, setUnitMeasure] = useState("")

  const yAxisLabel = displayValue == "invoiceQuantity" ? unitMeasure : "USD";
  const fetchingAggregate = customerPartId == "All Parts";
  const params = fetchingAggregate ? {} : { customer_part_id: customerPartId };

  const addUnitForecast = (forecasts) => {

    return forecasts.map(forecast => {
      let price = Number(forecast["price"]);
      if (price > 0) {
        forecast["invoiceQuantity"] = (Number(forecast["cost"]) / price);
      } else {
        forecast["invoiceQuantity"] = 0.0;
      }
      return forecast;
    })
  }

useEffect(() => {
  getData("api/part_sales_months", params).then(res => {
    // show monthly aggregate sales ("all parts") in dollars only
    if (fetchingAggregate) {
      setDisplayValue("cost")
    }

    const partSalesMonths = res.data.partSalesMonths
    const partSalesMonthForecasts = displayValue == "cost" ? res.data.partSalesMonthForecasts : addUnitForecast(res.data.partSalesMonthForecasts)
    const relevantData = _.concat(partSalesMonths, partSalesMonthForecasts)

    setData(relevantData)
    setConsistentUM(res.data.consistentUnitMeasure)
    setUnitMeasure(res.data.unitMeasure)
  })
}, [customerPartId, displayValue])

// console.table(data)

// DATA FORMAT:
// const data = [
//   { name: 'Jan', "2018": 4000, "2019": 2400, "2020": 2400 },
//   { name: 'Feb', "2018": 3000, "2019": 1398, "2020": 2210 }
//   ...
// ];
const transformData = (data) => {
  const dataByMonth = _.groupBy(data, "salesMonth");

  return _.map(dataByMonth, (years, month) => {
    const entry = {}

    entry["name"] = years[0]["month"]

    years.map(year => {
      const isForecast = year["isForecast"] === true
      const name = `${year["salesYear"]}${isForecast ? "F" : ""}`
      entry[name] = Number(year[displayValue])
    })

    return entry;
  })
}

const handleYAxisFormat = (tick) => {
  if (displayValue == "cost") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(tick)
  }

  const quantity = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(tick)

  return `${quantity} ${unitMeasure}`
}

const formatToolTip = (value, name, _props) => {
  return [
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value),
    name
  ]
}

const generateGraphDataKeys = (data) => {
  const transformedData = transformData(data)
  let accumulatedKeys = []

  const chartKeys = transformedData.map(monthlyData => (
    accumulatedKeys = _.concat(accumulatedKeys, Object.keys(monthlyData))
  ))

  accumulatedKeys = _.uniq(accumulatedKeys)
  accumulatedKeys = accumulatedKeys.filter(key => key != "name")
  accumulatedKeys = _.sortBy(accumulatedKeys)

  return accumulatedKeys;
}

const dataKeys = generateGraphDataKeys(data)

return (
  <>
    {!consistentUM && (
      <div>
        This part has been ordered in differing units of measure over the displayed time period.
        Please contact your representative with questions.
      </div>
    )}
    {!fetchingAggregate && (
      <div className="u-center">
        <button className={`${displayValue == "cost" ? "btn--active u-push-right" : "u-push-right"}`} onClick={() => setDisplayValue("cost")}>Dollars</button>
        <button className={`${displayValue == "invoiceQuantity" ? "btn--active" : ""}`} onClick={() => setDisplayValue("invoiceQuantity")}>Units</button>
      </div>
    )}
    <h3 className="u-right u-push-bottom">{customerPartId} History</h3>
    <div style={{ width: "100%", height: "580px" }}>
      <ResponsiveContainer>
        <LineChart
          width={500}
          height={400}
          data={transformData(data)}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid stroke="#BECED9" />
          <XAxis dataKey="name" stroke="#BECED9" />
          <YAxis stroke="#BECED9" tickFormatter={handleYAxisFormat} />
          <Tooltip formatter={formatToolTip} cursor={{ stroke: "#0698FC", strokeWidth: 2 }} contentStyle={{ backgroundColor: "#144E72", borderColor: "#FFC602" }} />
          <Legend iconType="circle" />
          <Line type="monotone" dataKey={dataKeys[0]} stroke="#7db0ce" strokeWidth="3" dot={{ r: 4, fill: "#7db0ce" }} activeDot={{ r: 5, fill: "#7db0ce", stroke: "#7db0ce" }} />
          <Line type="monotone" dataKey={dataKeys[1]} stroke="#1a81c7" strokeWidth="3" dot={{ r: 4, fill: "#1a81c7" }} activeDot={{ r: 5, fill: "#1a81c7", stroke: "#1a81c7" }} />
          <Line type="monotone" dataKey={dataKeys[2]} stroke="#F5F7F9" strokeWidth="3" dot={{ r: 4, fill: "#F5F7F9" }} activeDot={{ r: 5, fill: "#F5F7F9", stroke: "#F5F7F9" }} />
          <Line type="monotone" dataKey={dataKeys[3]} stroke="#FFC602" strokeWidth="3" dot={{ r: 4, fill: "#FFC602" }} activeDot={{ r: 5, fill: "#FFC602", stroke: "#FFC602" }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </>
)
}

export default PartHistoryChart;
