import React, { useState, PureComponent } from "react";
import ReactDOM from "react-dom"
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import PartHistoryChart from "./part-history-chart"
import { stripAndDowncase } from "../../utilities"

const PartHistory = ({ impersonating, partList }) => {
  const AUTOSUGGEST_LIST_MAX_LENGTH = 10;

  const partsList = JSON.parse(partList)
  partsList.unshift({ "customer_part_id": "All Parts", "ap_part_id": "All Parts" })

  const [partIds, setPartIds] = useState(partsList)
  const [selectedPartId, setSelectedPartId] = useState("All Parts") // customer_part_id
  const [searchQuery, setSearchQuery] = useState("")
  const [searching, setSearching] = useState(false)

  const impersonatingOrganization = (impersonating == "true")

  const matchingParts = partIds.filter(part => {
    const { ap_part_id : apPartId, customer_part_id: customerPartId } = part;

    const target = stripAndDowncase(searchQuery)

    const matchesCustomerPartId = _.startsWith(stripAndDowncase(customerPartId), target);
    const matchesApPartId = _.startsWith(stripAndDowncase(apPartId), target);

    if (impersonatingOrganization) {
      return matchesCustomerPartId || matchesApPartId;
    }

    return matchesCustomerPartId;
  });

  const handleSearchQuery = (query) => {
    setSearchQuery(query)

    if (query.length == 0) {
      setSearching(false)
      setSelectedPartId("All Parts")
    } else {
      setSearching(true)
    }
  }

  const handlePartSelection = (customerPartId, apPartId) => {
    setSelectedPartId(customerPartId)
    setSearching(false)

    if (impersonatingOrganization) {
      setSearchQuery(`${customerPartId} - ${apPartId}`)
    } else {
      setSearchQuery(`${customerPartId}`)
    }
  }

  return (
    <>
    <div className="list__header u-push-bottom--lg">
      <div className="list__title u-left">
        <h2 className="u-push-right--sm">Order History</h2>
      </div>
      <div>
      </div>
      <div className="list__search u-right">

        <div className="input--search">
          <input
            type="text"
            onChange={(e) => handleSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder={"Search by part #"}
          />

          {searching && (
            <ul className="search__list">
              {searching && matchingParts.length == 0 && (
                <>
                  No matching parts were found
                </>
              )}
              {matchingParts.slice(0, AUTOSUGGEST_LIST_MAX_LENGTH).map(({ ap_part_id : apPartId, customer_part_id : customerPartId }) => {
                const apPartString = impersonatingOrganization ? ` - ${apPartId}` : "";

                return (
                  <li
                    key={`${apPartId}-${customerPartId}`}
                    onClick={() => handlePartSelection(customerPartId, apPartId)}
                  >
                    {customerPartId} {apPartString}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
    <div className="u-push-top grid">
      <div className="grid__child--span-2 card--dark">
        <PartHistoryChart customerPartId={selectedPartId} />
      </div>
    </div>
    <div className="u-padded--lg">
    </div>
    </>
  )
}

export default PartHistory;

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-component=\"part-history\"]"))
    .forEach((node) => {
        const props = node.dataset;

        ReactDOM.render(<PartHistory {...props} />, node)
    })
});
