import React, { useState } from "react"
import ReactDOM from "react-dom"
import { formatDate, truncate } from "../../utilities";

const ShipmentSummary = (props) => {
  const shipperTrackingUrl = (props) => {
    if (props.shippingMethod == "FEDEX") {
      return `https://www.fedex.com/fedextrack/summary?trknbr=${props.shipperTrackingNumbers}`;
    } else if (props.shippingMethod == "UPS") {
      return `https://www.ups.com/track?tracknum=${props.shipperTrackingNumbers.replaceAll(",", "%20")}`;
    }
  }

  const tracking = (props) => {
    if (props.bolFlag && props.tracking !== "N/A") {
      return ( <a href={ `${props.tracking}.pdf` } target="_blank" data-test="bill_of_lading"><span className="material-icons">description</span> bill of lading pdf</a> );
    } else if (props.shippingMethod == "FEDEX" || props.shippingMethod == "UPS") {
      return ( <div data-tooltip={props.shipperTrackingNumbers.replaceAll(",", ", ")}><span className={`shipper--${props.shippingMethod.toLowerCase()} caption`}>{props.shippingMethod}</span><br /><a href={shipperTrackingUrl(props)} target="_blank">{truncate(props.shipperTrackingNumbers)}</a></div> );
    } else {
      return props.tracking;
    }
  }

  return (
    <>
      <li className="expander">
        <div className="list__table">
          <div className="list__cell">
            <p data-test-id={`accura-batch-id-${props.accuraBatchId}-ship-date`}>{formatDate(props.shipDate, { format: "MM/DD/YYYY" })}</p>
          </div>
          <div className="list__cell" data-test-shipment-number={props.accuraBatchId}>
            {props.accuraBatchId}
          </div>
          <div className="list__cell">
            {props.packingListId && (
              <a href={`/packing_lists/${props.packingListId}.pdf`} target="_blank" data-test="packing_list"><span className="material-icons">description</span> packing list pdf</a>
            )}
          </div>
          <div className="list__cell tracking" data-test-tracking={props.accuraBatchId}>
            {tracking(props)}
          </div>
          {props.displayCoc && (
            <div className="list__cell">
              {props.showCertificateOfConformance && (
                <a href={props.certificateOfConformanceUrl} target="_blank"><span className="material-icons">description</span>COC pdf</a>
              )}
            </div>
          )}
          {props.displayCoa && (
            <div className="list__cell">
              {props.showCertificateOfAnalysis && (
                <a href={props.certificateOfAnalysisUrl} target="_blank"><span className="material-icons">description</span>COA pdf</a>
              )}
            </div>
          )}
          <div className="list__cell">
            <a href={props.csvPath}><span className="material-icons">description</span> export csv</a>
          </div>
          <div className="list__cell--xlg caption">
            <b>{props.invoices.map(invoice => invoice.accuraInvoiceId).join(", ")}</b>
          </div>
        </div>
      </li>
    </>
  )
}

export default ShipmentSummary;
