import moment from "moment"

export const formatCurrency = (value, options = { digits: 2 }) => {
  const minimumFractionDigits = (options?.digits == 0 ? 0 : options?.digits);
  const maximumFractionDigits = (options?.digits == 0 ? 0 : options?.digits);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  }).format(value)
}

export const formatDate = (date, options = { format: "MMM-DD-YYYY", prevMonth: false }) => {
  let parsedDate = moment(date);
  if (options.prevMonth) { parsedDate = parsedDate.subtract(1, "day"); }

  return parsedDate.utc().format(options.format);
}

export const formatQuantity = (value, options = { digits: 4 }) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: (options.digits || 4),
    maximumFractionDigits: (options.digits || 4)
  }).format(value);
}

export const stripAndDowncase = (string) => {
  if (string) {
    const regex = /\s|\.|-/gm // white space, periods, hyphens
    return string.replace(regex, "").toLowerCase()
  }

  return "";
}

export const truncate = (string, options = { truncationLimit: 17 }) => {
  if(string) {
    return string.length > options.truncationLimit + 3 ? `${string.substring(0, options.truncationLimit)}...` : string;
  }

  return "";
}
